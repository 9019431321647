import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const ngI18nClosureMode = false;

if (environment.production) {
  enableProdMode();
}

import { googleAnalyticsHeadScripts } from './assets/script';

if (!window.location.search.includes('metrics=false')) {
    googleAnalyticsHeadScripts();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
