import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { GoaaaEnvironment } from '@goaaa-mwg-tt/ionic-common';

import * as Sentry from '@sentry/browser';

/**
 * Enables logging to StackDriver.
 */
@Injectable()
export class SentryLoggingService {

    private _appName: string;
    private _appVersion: string;
    private _user: string;
    private _serviceCallId: string;

    constructor(private http: HttpClient,
        private env: GoaaaEnvironment) {
        Sentry.init({
            dsn: 'https://d8597dbac0644070bf9b64ed9e95dcbf@sentry.io/1729830',
            environment: this.env.projectId,
            integrations(integrations) {
                return integrations.filter(i => i.name !== 'TryCatch');
            }
        });
        Sentry.setTag('serviceName', 'aaa-otw-member-app');
    }

    set appName(name) {
        this._appName = name;
    }

    set appVersion(version) {
        this._appVersion = version;
        Sentry.setTag('serviceVersion', this._appVersion);
    }

    set user(user) {
        this._user = user;
    }

    set serviceCallId(serviceCallId) {
        this._serviceCallId = serviceCallId;
    }

    error(error: any, extra: any = null) {
        if (!error) { return; }
        if (typeof error === 'string') {
            return this.message(error, extra);
        }
        Sentry.withScope((scope) => {
            // scope.setLevel('error');
            scope.setTag('serviceCallId', this._serviceCallId);
            scope.setTag('memberAccessToken', this._user);
            // scope.setTag('version', this._appVersion);
            if (extra) {
                scope.setExtra(extra.key, extra.content);
            }
            if (error instanceof HttpErrorResponse || error.hasOwnProperty('status')) {
                scope.setLevel(Sentry.Severity.Error);
                scope.setExtra('url', error.url);
                if (error.hasOwnProperty('error')) {
                    scope.setExtra('message', error.error.message);
                }
                Sentry.captureMessage(`HTTP Error: ${error.status}`);
            } else {
                Sentry.captureException(error.originalError || error);
            }
        });
    }

    message(message: string, extra: any = null, severityLevel: string = 'info') {
        Sentry.withScope((scope) => {
            scope.setLevel(Sentry.Severity.fromString(severityLevel));
            scope.setTag('serviceCallId', this._serviceCallId);
            scope.setTag('memberAccessToken', this._user);
            // scope.setTag('version', this._appVersion);
            if (extra) {
                scope.setExtra(extra.key, extra.content);
            }
            Sentry.captureMessage(message);
        });
    }
}
