export class AppConst {
    // public static LABEL_MEMBER_NUMBER = 'Number';
    public static THANKYOU_HEADING =
        'Thank you for choosing AAA';
    public static THANKYOU_SUBHEADING =
        'We see your service is complete!';
    public static THANKYOU_FEEDBACK_HEADING =
        'Please take a moment to let us know how we did.';
    public static LABEL_THANKYOU_FEEDBACK = 'Any comments?';
    public static LABEL_THANKYOU_FEEDBACK_OPT = 'Optional';
    public static DONE = 'Done';
    public static HELP_ON_WAY = 'Help is on the way';
    public static GOT_YOUR_REQUEST = 'We got your #SERVICE request!';

    public static LABEL_EXPECT_DRIVER_AT = 'Expect your technician at';
    public static LABEL_DRIVER_ON_WAY = 'Your technician is on the way!';
    public static LABEL_CALL_DRIVER = 'Call Driver';
    public static LABEL_TEXT_DRIVER = 'Text Driver';
    public static LABEL_DRIVER_ARRIVED = 'Your technician has arrived';
    public static LABEL_DRIVER_TOWING = 'Your vehicle is being towed';
    public static LABEL_DRIVER_VISIBLE = 'You should be able to see your technician.';
    public static LABEL_DRIVER_NOT_VISIBLE = `I can't see my technician.`;
    public static LABEL_DRIVER_BIT_CLOSER = `We found a technician who's a bit closer!`;

    /*tslint:disable*/
    public static LOOKING_FOR_TRUCK = 'We\'re looking for a #SERVICE truck for you. This normally takes several minutes. We\'ll text you and refresh this screen with updates.';
    public static STILL_WORKING_ON_REQ = 'We\'re still working on your request';

    public static APPRECIATE_PATIENCE = 'We appreciate your patience. We\'re still getting a #SERVICE truck for you. We\'ll text you and refresh this screen with updates when a technician is on the way.'
    public static THANKS_FOR_RELYING = 'Thanks for relying on AAA to help when you needed it most!';
    public static CANT_FIND_CALL = `Can't find your service call.`;
    public static CALL_NOT_READY = `Tracking for your service call is not ready yet.`
    public static SOMETHING_NOT_RIGHT = 'Something\'s not quite right!';
    public static TRY_REFRESHING = 'Please try refreshing the page in a few minutes to see your AAA tracker.';
    public static YOR_ARE_ALL_DONE = 'You\'re all done and can close this tab.';
    public static NO_CONNECTION_TO_TRUCK = 'Can\'t connect to truck';
    public static DRIVER_STILL_COMING = `Your technician is still on the way.`;
    public static CALL_AAA = `Please call AAA if you need further assistance.<br>\
    <a href="tel: 8002224357">(800) 222-4357</a>`;

    public static TIME_10_SECONDS = 10000;
    public static TIME_3_SECONDS = 3000;
    public static MAP_ZOOM_RESET_TIMER = AppConst.TIME_10_SECONDS;
    public static MAP_AUTO_ZOOM_TIMER = AppConst.TIME_3_SECONDS;

    public static PAGE_ROUTES = {
        driverAssigned: 'driver-assigned',
        lookingForDriver: 'looking-for-driver',
        thankYou: 'thank-you'
    }
}
