import { EnvConfig } from '../environments/environment';

const env = new EnvConfig();

export function googleAnalyticsHeadScripts(){
    const head = document.getElementsByTagName('head')[0];

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token')

    const googleAnalyticsFirstScript = document.createElement('script');
    googleAnalyticsFirstScript.innerHTML = `
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

        var GA_LOCAL_STORAGE_KEY = 'ga:clientId:${token}';

        if (window.localStorage) {
            ga('create', '${env.analyticsTrackingCode}', {
                'storage': 'none',
                'clientId': localStorage.getItem(GA_LOCAL_STORAGE_KEY)
            });
            ga(function(tracker) {
                localStorage.setItem(GA_LOCAL_STORAGE_KEY, tracker.get('clientId'));
                console.log('GA clientId: ', tracker.get('clientId'));
            });
            }
        else {
            ga('create', '${env.analyticsTrackingCode}', 'auto');
        }

        ga('set', 'transport', 'beacon');
        `;
    head.insertBefore(googleAnalyticsFirstScript, head.firstChild);
  }

