import { Injectable } from '@angular/core';
import { AppHttpInterceptorService, GoaaaEnvironment } from '@goaaa-mwg-tt/ionic-common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class MemberHttpInterceptorService
    extends AppHttpInterceptorService {

    constructor(env: GoaaaEnvironment, private router: Router, http: HttpClient) {
        super(env, http);
    }

    /**
     * This method is used to navigate to 'ErrorPage' along with error type
     * to handle errors in Error page accordingly.
     * @param err Error
     */
    handleError(err) {
        // if (err instanceof HttpErrorResponse) {
        //     switch (err.status) {
        //         case 416:
        //             this.router.navigate(['/error/416'], { queryParamsHandling: 'preserve' });
        //             break;
        //         case 417:
        //             this.router.navigate(['/error/417'], { queryParamsHandling: 'preserve' });
        //             break;
        //         default:
        //             this.router.navigate(['/error/500'], { queryParamsHandling: 'preserve' });
        //             break;
        //     }
        // }
        throw err;
    }
}
