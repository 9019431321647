import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { SentryLoggingService } from './services/sentry-logging.service';
import { AppDataService } from './services/app-data.service';

@Injectable()
export class AppErrorHandler implements ErrorHandler {

    constructor(
        private injector: Injector,
        private log: SentryLoggingService,
        private appData: AppDataService,
        private zone: NgZone) {
    }

    handleError(err: any): void {

        // Automatcially reload the page if a chunk fails to load.
        // This happens if a new version of the app is deployed and parts of the old app are cached and used by the browser
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (err.hasOwnProperty('message') && chunkFailedMessage.test(err.message)) {
            window.location.reload();
            return;
        }

        // If the call is closed and an unexpected error occurs (ie. firestore listener errors), don't switch to any error page
        if (this.appData && this.appData.serviceCallStatus && this.appData.serviceCallStatus.current === 'CL') {
            return;
        }

        console.log('In AppErrorHandler.handleError()');
        console.log(err);
        this.log.error(err, null);
        const router = this.injector.get(Router);
        this.zone.run(() => {
            let route = '/error';
            if (err.hasOwnProperty('status')) {
                route += `/${err.status}`;
            }
            router.navigate([route], { queryParamsHandling: 'preserve' });
        });
    }
}
