import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppConst } from './app-const';

/*tslint:disable*/
const routes: Routes = [
    { path: AppConst.PAGE_ROUTES.driverAssigned, loadChildren: './driver-assigned/driver-assigned.module#DriverAssignedPageModule', data: {title: 'TechnicianAssigned'} },
    { path: 'error', loadChildren: './error/error.module#ErrorPageModule', data: {title: 'Error'} },
    { path: 'error/:error', loadChildren: './error/error.module#ErrorPageModule', data: {title: 'Error'} },
    { path: AppConst.PAGE_ROUTES.lookingForDriver, loadChildren: './looking-for-driver/looking-for-driver.module#LookingForDriverPageModule', data: {title: 'LookingForTechnician'} },
    { path: AppConst.PAGE_ROUTES.thankYou, loadChildren: './thank-you/thank-you.module#ThankYouPageModule', data: {title: 'ThankYou'} },
    { path: 'loading', loadChildren: './loading/loading.module#LoadingPageModule', data: {title: 'Loading'} },
];

@NgModule({
    // imports: [RouterModule.forRoot(routes, {initialNavigation: false})],
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
