import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { GoaaaEnvironment } from '@goaaa-mwg-tt/ionic-common';
import { FirebaseDataService } from './services/firebase-data.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { InjectableWindowRef } from './services/browser-globals';
import { AngularPageVisibilityService } from 'angular-page-visibility';
import { AppDataService } from './services/app-data.service';
import { SentryLoggingService } from './services/sentry-logging.service';
import { filter, map } from 'rxjs/operators';

declare var ga: any;

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {
    timeTracking: any;
    allowMetrics: boolean;
    userId: string;

    constructor(
        private platform: Platform,
        private fb: FirebaseDataService,
        private env: GoaaaEnvironment,
        private router: Router,
        private route: ActivatedRoute,
        private w: InjectableWindowRef,
        private visibility: AngularPageVisibilityService,
        private appData: AppDataService,
        private log: SentryLoggingService
    ) {
        this.initializeApp();
    }

    initializeApp() {
        console.log(`Running in ${this.env.name} environment...`);
        console.log(`AuthKey: ${this.env.authKey}`);

        this.log.appVersion = '1.00.001';

        this.timeTracking = {
            visible: {
                start: null,
                total: null
            },
            hidden: {
                start: null,
                total: null
            }
        };

        const _window = this.w.getNativeWindow();
        const perf = _window.performance;

        this.allowMetrics = !_window.location.search.includes('metrics=false');

        if (perf) {
            this.visibility.$onPageVisible.subscribe(() => {
                // If we are transitioning to visible, we need to update the total hidden time
                this.timeTracking.visible.start = perf.now();
                this.timeTracking.hidden.total +=
                    this.timeTracking.visible.start - (this.timeTracking.hidden.start || 0);
                console.log(`Total hidden time: ${this.timeTracking.hidden.total}`);
            });
            this.visibility.$onPageHidden.subscribe(() => {
                // If we are transitioning to hidden, we need to update the total visible time
                this.timeTracking.hidden.start = perf.now();
                this.timeTracking.visible.total +=
                    this.timeTracking.hidden.start - (this.timeTracking.visible.start || 0);
                console.log(`Total visible time: ${this.timeTracking.visible.total}`);
            });
        }

        // Set up listener for routing events in order to send page views to Google Analytics
        if (this.allowMetrics) {
            const appTitle = 'AAA On The Way';
            const scSub = this.appData.serviceCallDetailsObservable.subscribe((serviceCall) => {
                if (serviceCall) {
                    console.log(`Sending userId of ${serviceCall.memberId} to GA`);
                    this.userId = serviceCall.memberId;

                    // Since the userId won't change for the life of the serviceCall, unsubscribe
                    // right away to prevent multiple events from triggering
                    scSub.unsubscribe();
                }
            });
            this.router
                .events.pipe(
                    filter(event => event instanceof NavigationEnd),
                    map(() => {
                        const child = this.route.firstChild;
                        let title = child.snapshot.data['title'] ? child.snapshot.data['title'] : appTitle;
                        const errorCode = child.snapshot.params['error'] ? child.snapshot.params['error'] : null;
                        const token = child.snapshot.queryParams['token'] ? child.snapshot.queryParams['token'] : null;
                        let url = '/' + child.pathFromRoot
                            .map(r => r.snapshot.url)
                            .filter(f => !!f[0])
                            .map(([f]) => f.path)
                            .join('/');
                        if (errorCode) {
                            url += `/${errorCode}`;
                            title += `: ${errorCode}`;
                        }
                        return { title: title, path: url, token: token };
                    })
                ).subscribe((pageData) => {
                    // Set the page, title and custom dimensions so they are reflected in other events as well
                    const gaData = {
                        'page': pageData.path,
                        'title': pageData.title,
                        'dimension1': this.appData.serviceCallDetails ? this.appData.serviceCallDetails.serviceCallId : null,
                        'dimension2': this.appData.memberAccessToken,
                        'userId': this.userId
                    };
                    ga('set', gaData);
                    ga('send', 'pageview', gaData);
                });
        }

        // Set up app shutdown handler
        _window.addEventListener('beforeunload', () => {
            if (perf && this.allowMetrics) {
                console.log(this.timeTracking);
                // Handle the last chunk of time before this event
                if (this.visibility.isPageVisible()) {
                    this.timeTracking.visible.total +=
                        perf.now() - (this.timeTracking.visible.start || 0);
                } else {
                    this.timeTracking.hidden.total +=
                        perf.now() - (this.timeTracking.hidden.start || 0);
                }
                const visibleTime = Math.round(this.timeTracking.visible.total) || 0;
                console.log(`Submitting visible time: ${visibleTime}`);
                const token = this.appData.memberAccessToken;
                ga('send', {
                    hitType: 'timing',
                    timingCategory: 'Session',
                    timingVar: 'total_visible',
                    timingValue: visibleTime,
                    timingLabel: token,
                    transport: 'beacon'
                });
                const hiddenTime = Math.round(this.timeTracking.hidden.total) || 0;
                console.log(`Submitting hidden time: ${hiddenTime}`);
                ga('send', {
                    hitType: 'timing',
                    timingCategory: 'Session',
                    timingVar: 'total_hidden',
                    timingValue: hiddenTime,
                    timingLabel: token,
                    transport: 'beacon'
                });
            }
            console.log('Unsubscribing from Firebase listeners.');
            this.fb.clearListeners();
        });

        this.platform.ready().then(() => {
        });
    }

    ngOnInit() {
        const sub = this.route.queryParams.subscribe((params) => {
            // Once the token query param is ready
            // console.log(params);
            if (!this.w.getNativeWindow().location.href.includes('?')) {
                this.router.navigate(['error']);
            } else if (params.token) {
                sub.unsubscribe();
                const qParams = { token: params.token };
                if (params.metrics) {
                    qParams['metrics'] = params.metrics;
                }
                this.router.navigate(['loading'], { queryParams: qParams });
            }
        });
    }
}
