import { Observable, of, throwError, MonoTypeOperatorFunction } from 'rxjs';
import { delay, mergeMap, retryWhen } from 'rxjs/operators';

const getErrorMessage = (maxRetries: number) =>
    `HTTP error: Retried ${maxRetries} times before giving up.`;

const DEFAULT_MAX_RETRIES = 5;
const DEFAULT_EXP_BACKOFF_DELAY_IN_MS = 1000;

export function retryWithBackoff<T>(minDelayInMs: number,
    maxRetry = DEFAULT_MAX_RETRIES,
    exponentialBackoffDelay = DEFAULT_EXP_BACKOFF_DELAY_IN_MS, shouldRetryCallback = null): MonoTypeOperatorFunction<T> {

    let retries = maxRetry;

    return (src: Observable<any>): Observable<any> => {
        return src.pipe(
            retryWhen((errors: Observable<any>) => errors.pipe(
                mergeMap((error) => {
                    if (shouldRetryCallback && !shouldRetryCallback(error)) {
                        return throwError(error);
                    }
                    if (retries-- > 0) {
                        const backoffTime = minDelayInMs + (((Math.pow(2, (maxRetry - retries)) - 1) / 2) * 1000);
                        return of(error).pipe(delay(backoffTime));
                    }
                    return throwError(error);
                })
            ))
        )
    }
}
