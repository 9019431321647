import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
    CommonAppSettingsProvider, GeolocationSnapshot
} from '@goaaa-mwg-tt/ionic-common';

export class ServiceCallStatus {
    current: string;
    last: string;

    constructor(last: string, current: string) {
        this.current = current;
        this.last = last;
    }
}

@Injectable({
    providedIn: 'root'
})
export class AppDataService extends CommonAppSettingsProvider {

    /**
     * The internal member access token representation.
     *
     * It is implemented as a BehaviorSubject for the following reasons:
     * <ul><li>If only a value is needed (not an observable), BehaviorSubjects
     *  track their last stored value.</li>
     * <li>An observable can still be returned so that other components can subscribe.</li>
     * <li>BehaviorSubjects are initialized with a default value. When a subscriptions is
     *  created, the default or last written value is returned immediately so if there are
     *  any delays in subscription creation, an initial value is always available. See the
     *  following reference:
     *  <a href="https://stackoverflow.com/questions/39494058/behaviorsubject-vs-observable">
     *  https://stackoverflow.com/questions/39494058/behaviorsubject-vs-observable</a>
     * </li></ul>
     */
    private _memberAccessToken: BehaviorSubject<string> = new BehaviorSubject(null);
    private _serviceCallStatus: BehaviorSubject<ServiceCallStatus> = new BehaviorSubject(null);
    private _truckDataAvailable: BehaviorSubject<boolean> = new BehaviorSubject(null);
    private _truckLocation: BehaviorSubject<GeolocationSnapshot> = new BehaviorSubject(null);
    private _truckLocationWatchdogRef: BehaviorSubject<number> = new BehaviorSubject(null);
    private _otwEnabled: BehaviorSubject<boolean> = new BehaviorSubject(null);
    // private _paramName: BehaviorSubject<string> = new BehaviorSubject(null);

    constructor() {
        super();
    }

    // TODO - Use the template below to add any global app settings that are unique
    //  to this app (ie. does not belong in CommonAppSettingsProvider)

    // get paramName() {
    //     return this._paramName.value;
    // }
    // get paramNameObservable() {
    //     return this._paramName.asObservable();
    // }
    // set paramName(val) {
    //     // TODO - Do validation if necessary before writing the new value
    //     this._paramName.next(val);
    // }

    get memberAccessToken() {
        return this._memberAccessToken.value;
    }
    get memberAccessTokenObservable() {
        return this._memberAccessToken.asObservable();
    }
    set memberAccessToken(token) {
        // TODO - Do validation if necessary before writing the new value
        if (token === undefined || token === this._memberAccessToken.value) {
            return;
        }
        this._memberAccessToken.next(token);
    }

    get serviceCallStatus() {
        return this._serviceCallStatus.value;
    }
    get serviceCallStatusObservable() {
        return this._serviceCallStatus.asObservable();
    }
    set serviceCallStatus(status) {
        // TODO - Do validation if necessary before writing the new value
        if (status === undefined || status === this._serviceCallStatus.value) {
            return;
        }
        this._serviceCallStatus.next(status);
    }

    get truckDataAvailable() {
        return this._truckDataAvailable.value;
    }
    get truckDataAvailableObservable() {
        return this._truckDataAvailable.asObservable();
    }
    set truckDataAvailable(dataAvailable) {
        const _dataAvailable = dataAvailable || false;
        // TODO - Do validation if necessary before writing the new value
        if (_dataAvailable === this._truckDataAvailable.value) {
            return;
        }
        this._truckDataAvailable.next(_dataAvailable);
    }

    get truckLocation() {
        return this._truckLocation.value;
    }
    get truckLocationObservable() {
        return this._truckLocation.asObservable();
    }
    set truckLocation(location) {
        // TODO - Do validation if necessary before writing the new value
        if (location === undefined) {
            return;
        }
        this._truckLocation.next(location);
    }

    get truckLocationWatchdogRef() {
        return this._truckLocationWatchdogRef.value;
    }
    set truckLocationWatchdogRef(ref) {
        // TODO - Do validation if necessary before writing the new value
        if (ref === undefined) {
            return;
        }
        if (ref == null && this._truckLocationWatchdogRef.value) {
            clearInterval(this._truckLocationWatchdogRef.value);
            console.log('Clearing truck location watchdog timer');
        }
        if (ref !== null) {
            console.log('Setting up truck location watchdog timer');
        }
        this._truckLocationWatchdogRef.next(ref);
    }

    get otwEnabled() {
        return this._otwEnabled.value;
    }
    get otwEnabledObservable() {
        return this._otwEnabled.asObservable();
    }
    set otwEnabled(enabled) {
        const _enabled = enabled || false;
        // TODO - Do validation if necessary before writing the new value
        if (_enabled === this._otwEnabled.value) {
            return;
        }
        this._otwEnabled.next(_enabled);
    }
}
