import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import {
    GoaaaMwgTtCommonModule,
    AppHttpInterceptorService, GoaaaEnvironment
} from '@goaaa-mwg-tt/ionic-common';
import { EnvConfig } from '../environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppErrorHandler } from './app-error-handler';
import { INJECTABLE_BROWSER_GLOBALS } from './services/browser-globals';
import { GoaaaMapsLoader } from './services/maps-loader.service';
import { MemberHttpInterceptorService } from './services/http-interceptor.service';
import { AppDataService } from './services/app-data.service';
import { AgmCoreModule } from '@agm/core';
import { AngularPageVisibilityModule, AngularPageVisibilityService } from 'angular-page-visibility';
import { SentryLoggingService } from './services/sentry-logging.service';


export function getConfig(): any {
    const env = new EnvConfig();
    if (env.name === 'prod') {
        console.log = function () { };
        // console.warn = function () { };
        // console.error = function () { };
    }
    return env;
}

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        GoaaaMwgTtCommonModule.forRoot(getConfig),
        HttpClientModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        AgmCoreModule.forRoot(),
        AngularPageVisibilityModule
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: ErrorHandler, useClass: AppErrorHandler },
        AppHttpInterceptorService,
        MemberHttpInterceptorService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MemberHttpInterceptorService,
            multi: true,
        },
        INJECTABLE_BROWSER_GLOBALS,
        GoaaaMapsLoader,
        AppDataService,
        AngularPageVisibilityService,
        SentryLoggingService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
