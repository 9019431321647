import {Provider, Injectable} from '@angular/core';

@Injectable()
export class InjectableWindowRef {
  getNativeWindow(): any { return window; }
}

@Injectable()
export class InjectableDocumentRef {
  getNativeDocument(): any { return document; }
}

export const INJECTABLE_BROWSER_GLOBALS: Provider[] = [InjectableWindowRef, InjectableDocumentRef];
